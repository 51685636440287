import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import ImgHeader from './assets/back_tour.jpg';
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';
import ImgTourMap from './assets/tour-map-2024.jpg';

const googleFormUrlPrefix = 'https://docs.google.com/forms/d/e/1FAIpQLSfCGdDmvOzn0DRLo55k_6bN8nj6S1icle3jpUj1lfdM8nauBg/viewform?usp=pp_url&entry.9960490=';

const tUrl = (str) => {
  return googleFormUrlPrefix + str.replace(/ /g, '+');
}

const googleMyMapsUrl = "https://www.google.com/maps/d/edit?mid=1GmHNAz_Q5kpEEMFTxNFGM8WwZN201OE&usp=sharing";
const wsjUrl = "https://www.wsj.com/articles/chatgpt-ai-math-po-shen-loh-1e9f80dc?st=0fg615evonrtpm1&reflink=desktopwebshare_permalink";

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
`;

const TourDivStyled = styled.div`
  font-size: 40px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
  text-align: center;

  .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
      background-color: #5755c3;
      border-color: #5755c3;
      width: 200px;
      height: 50px;
      border-radius: 30px;
      font-weight: bold;
  }
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
`;



export const Tour = () => {
  return (
    <Styles>
      <CustomJumbotron bgimage={ImgHeader}>Tour</CustomJumbotron>
      <Container style = {{paddingTop: 30, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

        <h2>
          Talks (mostly on ChatGPT's impact on math, jobs, and education)
        </h2>

        <PStyled>
          Curious why people are talking about these events? Read <a href={wsjUrl}>The Wall Street Journal's review of the talk.</a>
        </PStyled>

        <PStyled>
          Want Prof. Loh to speak to your whole school and its parent
          community? There is no cost for public schools, thanks to private
          schools which customarily provide an honorarium for invited
          Assembly speakers. <a
          href="https://www.roxburylatin.org/2024/01/24/dr-po-shen-loh-combines-ai-education-and-art/">Typical impact of talk.</a> To book, email <a
              href="mailto:hello@poshenloh.com">hello@poshenloh.com</a>.
        </PStyled>

        <PStyled>
          Scroll down for registration links. <a href={googleMyMapsUrl}>
            Click on map for scrollable, zoomable map</a>, with clickable
            map markers that say which date Prof. Loh will be there.
        </PStyled>

        <a href={googleMyMapsUrl}>
          <img src={ImgTourMap} alt="2023 Summer Tour Stop Map" style={{maxWidth: "100%"}} />
        </a>

        <PStyled>
          In the summer of <Link to="/tour2021">2021</Link>, Prof. Loh
          conducted the largest outdoor solo math tour in human history,
          holding events in public parks. They were so successful that he
          continued touring in <Link to="/tour2022">2022</Link>, and again
            in <Link to="/tour2023">2023</Link> when he gave 100+ talks in
          60+ cities over the summer. His current topic guides parents and
          students on how to dramatically adjust educational approaches in
          the era of ChatGPT. These fun events also inspire students to see
          math from a different angle and to embrace challenge. 
        </PStyled>

        <Row className="align-items-center" style={{marginTop: "2rem", marginBottom: "2rem"}}>
          <Col xs={12} lg={6}>
            <Video>
              <iframe
                title="live-iframe"
                style={{position: "absolute", top: 0, 
                  left: 0, width: "100%", height: "100%"}}
                src="https://www.youtube.com/embed/3sgCAXWLdYk"
                frameBorder="0"
                allowFullScreen
              />
            </Video>
          </Col>

          <Col xs={12} lg={6}>
            <PStyled>
              This tour is supported by <a
                href="https://live.poshenloh.com">LIVE</a>, the <a
                  href="https://www.cnn.com/world/professor-po-shen-loh-actors-classrooms-spc">new
                  online math school</a> that Prof. Loh founded, which
              revolutionizes live-video math instruction: teaching students
              the art of invention.
            </PStyled>

            <TourDivStyled>
              <a href="https://live.poshenloh.com">
                <Button variant="primary">
                  Learn LIVE
                </Button>
              </a>
            </TourDivStyled>
          </Col>
        </Row>

        <h2>
          Event Schedule (Spring 2025)
        </h2>

        <PStyled>
          Individual registration links to all upcoming tour stops are
          listed below. Register directly via the corresponding link if a
          time and location fit you. If you will miss (or already missed)
          Prof. Loh's visit to your area, you can also sign up to be
          notified whenever he comes next time. If the link does not go to
          a registration page, that particular event is not open to the
          public.
        </PStyled>

        <TourDivStyled>
          <a href="https://forms.gle/Qu2EoVBxtVAYmKHa6">
            <Button variant="primary">
              Notify me
            </Button>
          </a>
        </TourDivStyled>

        <OLStyled>
          <li><a href="https://clphs.edu.my">Penang, Malaysia (Chung Ling Private High School): Fri Jan 3</a></li>
          <li><a href="https://www.facebook.com/techdomepenang/photos/-thriving-in-the-age-of-ai-free-registration-now-open-the-future-of-work-and-edu/585912550854355">Penang, Malaysia (Tech Dome): Sat Jan 4 at 2:00pm</a></li>
          <li>Bogotá, Colombia (National Colombian Math Olympiad training camp): Tue Jan 7 and Wed Jan 8</li>
          <li><a href="https://maceio.com.br/educacao/workshop-internacional-de-altas-habilidades-em-ciencias-educadores-se-reunem-para-discutir-talentos-excepcionais">Maceió, Brazil (Workshop Internacional para Altas Habilidades em Ciências no Ensino Básico, Centro de Inovação do Jaraguá): Thu Jan 9 at 12:00pm</a></li>
          <li>Recife, Brazil (Colégio GGE): Fri Jan 10 at 1:30pm</li>
          <li><a href="https://cloud.conteudo.sistemaetapa.com.br/sigmacampbrazil">São Paulo, Brazil (Sigmacamp): Sat Jan 11</a></li>
          <li><a href="https://ralston.brssd.org">San Francisco (Belmont, Ralston Middle School): Tue Jan 14</a></li>
          <li><a href="https://nesbit.brssd.org">San Francisco (Belmont, Nesbit School): Tue Jan 14</a></li>
          <li><a href="https://www.caltechmathmeet.org">Los Angeles (Caltech Math Meet): Sat Jan 25</a></li>
          <li><a href="https://www.eventbrite.com/e/1143291508679">Orange County, CA (Lake Forest): Sat Jan 25 at 3:00pm</a></li>
          <li><a href="https://www.ctspe.net/mathcounts">Fairfield, CT (MATHCOUNTS Southwest CT Chapter Competition): Sat Feb 1</a></li>
          <li><a href="https://www.milton.edu/events/future-of-ai-with-po-shen-loh">Boston, MA (Milton Academy): Tue Feb 4 at 7:00pm</a></li>
          <li>Santa Fe, NM: Thu Feb 6</li>
          <li>Albuquerque, NM (Albuquerque Math Teachers Circle): Thu Feb 6</li>
          <li>Albuquerque, NM (University of New Mexico math department colloquium): Fri Feb 7</li>
          <li><a href="https://stat.unm.edu/news-events/events/unm-pnm-math-contest-public-lecture-po-shen-loh-carnegie-mellon-university">Albuquerque, NM (UNM-PNM Statewide Math Contest): Sat Feb 8 at 10:00am</a></li>
          <li><a href="https://www.mphschool.org">Syracuse, NY (Manlius Pebble Hill School): Tue Feb 11</a></li>
          <li><a href="https://insightsedu.educationdynamics.com/event/4e9323d9-d9df-44a2-95ce-70bfe4155686">New Orleans, LA (InsightsEDU conference keynote): Thu Feb 13</a></li>
          <li><a href="https://math.unl.edu/math-day-university-nebraska-lincoln">Lincoln, NE (University of Nebraska Lincoln Math Day): Tue Feb 18</a></li>
          <li>Orlando, FL (Florida Philanthropic Network Statewide Summit keynote): Thu Feb 20</li>
          <li>Greater China Region: Fri Feb 28 &ndash; Sun Mar 9</li>
          <li><a href="https://www.harkermathinvitational.org">San Francisco, CA (Harker Math Invitational): Sat Mar 15</a></li>
        </OLStyled>

        <h2>
          Previous Events in 2024
        </h2>

        <OLStyled>
          <li>Beijing (University of Chicago Alumni): Mon Jan 1 at 3:00pm</li>
          <li><a href="https://www.etuschool.org">Beijing (Etu School 一土学校): Tue Jan 2 at 1:00pm</a></li>
          <li><a href="https://www.bnds.cn">Beijing (Beijing National Day School 十一学校): Tue Jan 2 at 4:30pm</a></li>
          <li>Hangzhou, China (Greentown Yuhua Primary School 杭州绿城育华小学): Wed Jan 3 at 10:00am</li>
          <li><a href="https://bihz.basischina.com/">Hangzhou, China (BASIS International School): Wed Jan 3 at 4:30pm</a></li>
      <li>Hangzhou, China (Information Harbor 杭州湾信息港): Wed Jan 3 at 7:30pm</li>
          <li>Ningbo, China (Huamao Foreign Language School 宁波华茂外国语学校): Thu Jan 4 at 2:00pm</li>
          <li>Suzhou, China (Suzhou Foreign Language School 苏州外国语学校): Fri Jan 5 at 2:00pm</li>
          <li>Shanghai, China (DBS Tower 浦东陆家嘴环路1318号星展银行大厦): Sat Jan 6 at 12:50pm</li>
          <li>Shanghai, China (Qibao Dwight High School 上海七宝德怀特高级中学): Sat Jan 6 at 3:00pm</li>
          <li>Shenzhen, China (Shenzhen Experimental School 深圳实验学校): Sun Jan 7 at 10:00am</li>
          <li>Shenzhen, China (Green Oasis School 城市绿洲学校): Sun Jan 7 at 3:30pm</li>
          <li>Shenzhen, China: Sun Jan 7 at 8:00pm</li>
          <li>Shenzhen, China: Mon Jan 8 at 10:00am</li>
          <li>Shenzhen, China (Shen Wai International School 深圳外国语学校): Mon Jan 8 at 3:45pm</li>
          <li>Shenzhen, China (BASIS International School): Mon Jan 8 at 6:00pm</li>
          <li><a href="https://en.wikipedia.org/wiki/German_Swiss_International_School">Hong Kong (German Swiss International School): Tue Jan 9 at 11:50am</a></li>
          <li><a href="https://en.wikipedia.org/wiki/German_Swiss_International_School">Hong Kong (German Swiss International School): Tue Jan 9 at 2:00pm</a></li>
          <li><a href="https://en.wikipedia.org/wiki/German_Swiss_International_School">Hong Kong (German Swiss International School): Tue Jan 9 at 5:00pm</a></li>
          <li>Hong Kong (Victoria Shanghai Academy): Wed Jan 10 at 1:30pm</li>
          <li>Hong Kong (Dalton School): Wed Jan 10 at 7:00pm</li>
          <li>Hong Kong (St. Margaret's Co-educational English Secondary School): Thu Jan 11 at 3:30pm</li>
          <li><a href="https://iit2024.org/">Washington, DC (IIT2024 Global Conference): Sat Jan 13 at 3:10pm</a></li>
          <li><a href="https://momath.org/unlimited/">New York, NY (National Museum of Mathematics): Sun Jan 21 at 6:30pm</a></li>
          <li><a href="https://www.roxburylatin.org/2024/01/24/dr-po-shen-loh-combines-ai-education-and-art/">Boston, MA (Roxbury Latin School): Tue Jan 23</a></li>
          <li>Houston, TX (St. John's School): Thu Jan 25</li>
          <li><a href="https://www.caltechmathmeet.org/">Pasadena, CA (Caltech Math Meet): Sat Jan 27 at 9:15am</a></li>
          <li><a href="https://mathm.org/">New York, NY (MATHCOUNTS Manhattan): Sat Feb 3</a></li>
          <li>Boston, MA (Sharon): Thu Feb 8</li>
          <li><a href="http://institutecreativeproblemsolving.org/">Long Island, NY (Institute of Creative Problem Solving): Sat Feb 10 at 11:00am</a></li>
          <li>Miami, FL (American Heritage School): Tue Feb 13</li>
          <li><a href="https://www.hmmt.org/">Boston, MA (Harvard&ndash;MIT Math Tournament): Sat Feb 17</a></li>
          <li><a href="https://momath.org/unlimited/">New York, NY (National Museum of Mathematics): Sun Feb 25 at 6:30pm</a></li>
          <li><a href="https://www.humancapabilityinitiative.org/">Riyadh, Saudi Arabia (Human Capability Initiative): Wed Feb 28</a></li>
          <li>Beijing (Beijing Number 4 High School 北京四中): Fri Mar 1 at 6:30pm</li>
          <li>Beijing (Yale Beijing Center): Sat Mar 2 at 7:00pm</li>
          <li>Shanghai: Sun Mar 3 at 10:30am</li>
          <li>Shanghai (The Little Bridge Experimental Primary School 筑桥实验小学): Sun Mar 3 at 3:00pm</li>
          <li>Shanghai (Shanghai American School): Mon Mar 4 at 11:00am</li>
          <li>Nanjing (BASIS International School): Mon Mar 4 at 4:30pm</li>
          <li>Nanjing (Nanjing Foreign Language School 南京外国语学校): Mon Mar 4 at 7:30pm</li>
          <li>Suzhou (Suzhou Foreign Language School 苏州外国语学校): Tue Mar 5 at 6:00pm</li>
          <li>Shenzhen: Wed Mar 6 at 4:30pm</li>
          <li>Shenzhen (Futian BASIS International School): Thu Mar 7 at 5:30pm</li>
          <li>Hong Kong City University: Fri Mar 8 at 10:30am</li>
          <li>Hong Kong (MIT Hong Kong Innovation Node): Fri Mar 8 at 5:30pm</li>
          <li>Hong Kong Baptist University Affiliated School: Sat Mar 9 at 10:00am</li>
          <li>Hong Kong (CTF Education Group): Sat Mar 9 at 3:00pm</li>
          <li>Hong Kong (Bloom KKCA Academy): Sun Mar 10 at 10:30am</li>
          <li>Hong Kong: Sun Mar 10 at 2:30pm</li>
          <li><a href="https://www.hrpolicy.org/events/chro-summit/">Orlando, FL (HR Policy Association Annual CHRO Summit): Thu Mar 14</a></li>
          <li><a href="https://form.jotform.com/240655374041149">Boston, MA (Sharon): Sat Mar 16 at 10:15am</a></li>
          <li><a href="https://iswonline.org/event-5599214">Boston, MA (Indian Institutes of Technology Association of Greater New England and India Society of Worcester): Sat Mar 16 at 1:00pm</a></li>
          <li><a href="https://form.jotform.com/240667412593157">Boston, MA (Brookline): Sat Mar 16 at 5:00pm</a></li>
          <li><a href="https://momath.org/unlimited/">New York, NY (National Museum of Mathematics): Sun Mar 17 at 6:00pm</a></li>
          <li><a href="https://form.jotform.com/240746320999163">Philadelphia, PA: Sat Mar 23 at 10:30am</a></li>
          <li><a href="http://sections.maa.org/newjersey/Main/index.html">Gloucester County, NJ (MAA NJ Section meeting): Sat Mar 23 at 3:45pm</a></li>
          <li><a href="https://form.jotform.com/240295028559159">New York, NY (Great Neck): Sun Mar 24 at 3:00pm</a></li>
          <li><a href="https://ranchosolano.com/special-event-with-dr-po-shen-loh/">Phoenix, AZ (Rancho Solano School): Tue Mar 26 at 6:30pm</a></li>
          <li><a href="https://cmimc.math.cmu.edu/">Pittsburgh, PA (Carnegie Mellon Informatics and Mathematics Competition): Sat Apr 6</a></li>
          <li><a href="https://www.ommcofficial.org/REPS">New Brunswick, NJ (Rutgers Exposition in Problem Solving): Sun Apr 7 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/872592887817">New Brunswick, NJ: Sun Apr 7 at 4:00pm</a></li>
          <li>Raleigh, NC (Duke University): Tue Apr 9 at 6:00pm</li>
          <li>San Francisco, CA (Stratford Preparatory School): Thu Apr 11 at 6:30pm</li>
          <li>San Francisco, CA (Palo Alto High School): Fri Apr 12 at 7:30pm</li>
          <li><a href="https://www.stanfordmathtournament.com/competitions/smt-2024">San Francisco, CA (Stanford Math Tournament): Sat Apr 13 at 9:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/877564136957">San Francisco, CA (Sunnyvale): Sat Apr 13 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/881076973947">San Francisco, CA (Foster City): Sat Apr 13 at 7:30pm</a></li>
          <li><a href="https://berkeley.mt/events/bmmt-2024/">San Francisco, CA (Berkeley mini Math Tournament): Sun Apr 14 in morning</a></li>
          <li><a href="https://www.eventbrite.com/e/877563645487">San Francisco, CA (Fremont): Sun Apr 14 at 7:00pm</a></li>
          <li>Philadelphia, PA (Episcopal Academy): Tue Apr 16</li>
          <li><a href="https://form.jotform.com/240675020718150">Toronto: Sat Apr 20 at 10:30am</a></li>
          <li><a href="https://www.competitivekids.org/outsmarting-ai">Toronto (Brampton, with Competitive Kids STEM): Sat Apr 20 at 5:00pm</a></li>
          <li><a href="https://www.iitnt.org/events/l-e-t-s-talk-2024/">Dallas, TX (Indian Institute of Technology Alumni Association of North Texas, in Frisco): Sat Apr 27 at 11:30am</a></li>
          <li><a href="https://www.eventbrite.com/e/881411223697">Dallas, TX (Richardson): Sat Apr 27 at 3:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/881557039837">Dallas, TX (Richardson): Sat Apr 27 at 5:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885011101027">Houston, TX (The Woodlands): Sun Apr 28 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/884987339957">Houston, TX (Katy): Sun Apr 28 at 3:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885712880067">Houston, TX (Sugar Land): Sun Apr 28 at 7:00pm (full)</a></li>
          <li><a href="https://www.eventbrite.com/e/883724994247">Austin, TX: Mon Apr 29 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/891285407647">San Diego, CA: Tue Apr 30 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/887840884987">Arcadia, CA: Wed May 1 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/884993327867">Irvine, CA: Thu May 2 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/893837501027">Portland, OR: Fri May 3 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885045413657">Seattle, WA: Sat May 4 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/885043227117">Seattle, WA: Sat May 4 at 2:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885039927247">Seattle, WA: Sat May 4 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885098973857">Vancouver (White Rock): Sun May 5 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/894466863467">Minneapolis, MN: Tue May 7 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/895604937477">Salt Lake City, UT: Wed May 8 at 7:00pm</a></li>
          <li><a href="https://www.mathcon.org/">Chicago, IL (MathCON): Sat May 11</a></li>
          <li><a href="https://www.eventbrite.com/e/893844421727">Chicago, IL (Whitney Young Magnet High School): Sat May 11 at 4:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/897526785777">Howard County, MD: Sun May 12 at 2:30pm</a></li>
          <li><a href="https://www.mathcounts.org/programs/national-competition-schedule-glance">Washington, DC (MATHCOUNTS National Competition): Sun May 12, no talk, but at event</a></li>
          <li><a href="https://www.eventbrite.com/e/888363628527">Atlanta, GA: Tue May 14 at 7:00pm</a></li>
          <li><a href="https://www.scgssm.org/">Hartsville, SC (South Carolina Governor's School for Science and Mathematics): Wed May 15</a></li>
          <li><a href="https://www.eventbrite.com/e/885102775227">Charlotte, NC: Thu May 16 at 6:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885104871497">Raleigh, NC: Fri May 17 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885107800257">Great Falls (Fairfax County), VA: Sat May 18 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/885118592537">Montgomery County, MD: Sat May 18 at 2:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885112815257">Montgomery County, MD: Sat May 18 at 4:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/906177911527">West Windsor, NJ: Sun May 19 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/885123737927">Woodbridge, NJ: Sun May 19 at 2:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/905446844887">Woodbridge, NJ: Sun May 19 at 4:30pm</a></li>
          <li><a href="https://gifted.uconn.edu/wallace/">University of Connecticut (Wallace Symposium closing keynote): Tue May 21 at 1:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/900157945647">Syosset, NY: Tue May 21 at 7:00pm</a></li>
          <li>SUNY Stony Brook, Long Island, NY: Wed May 22 in evening (teachers)</li>
          <li>Great Neck, NY (Nassau County American Regions Math League team): Thu May 23</li>
          <li><a href="https://www.eventbrite.com/e/909999181047">Melbourne: Sun May 26 at 12:00pm, in Chinese</a></li>
          <li><a href={tUrl("VIC Melbourne")}>Melbourne: Sun May 26 at 7:00pm, in Chinese</a></li>
          <li><a href={tUrl("VIC Melbourne")}>Melbourne (Monash University, public lecture): Mon May 27 at 4:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/911657290497">Sydney: Tue May 28 at 7:00pm, in Chinese</a></li>
          <li><a href="https://events.humanitix.com/smri-public-lecture-using-maths-to-invent-solutions-to-large-scale-human-problems-just-in-time-to-survive-ai-with-po-shen-loh">Sydney (University of Sydney public lecture): Wed May 29 at 5:30pm</a></li>
          <li><a href="https://www.eventbrite.com.au/e/uniting-game-theory-maths-actors-to-build-human-intelligence-in-ai-age-tickets-904483132397">Sydney (University of New South Wales, for educators and university / graduate students): Thu May 30 at 1:00pm</a></li>
          <li><a href="https://events.humanitix.com/smri-high-school-student-workshop-unpacking-the-ai-survival-kit-with-po-shen-loh">Sydney (University of Sydney, for ambitious high school students with a creative mindset): Thu May 30 at 4:30pm</a></li>
          <li><a href="https://mathematical-research-institute.sydney.edu.au/event/po-shen-loh-sydney-2024/">Sydney (University of Sydney, seminar for mathematical scientists in academia or industry): Fri May 31 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/911653388827">Sydney: Fri May 31 at 7:00pm, in Chinese</a></li>
          <li><a href="https://event.ypo.org/event/10438a03-acf6-4d5c-ae86-feaec49f4100/">New York, NY (Young Presidents' Organization Global One Columbia Business School Academy): Wed Jun 26</a></li>
          <li><a href="https://www.mathmountains.org/">Jackson, WY (Math in the Mountains): Thu Jun 27 and Fri Jun 28</a></li>
          <li><a href="https://www.eventbrite.com/e/916424960727">San Francisco, CA (Palo Alto): Sat Jun 29 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/927994906747">San Francisco, CA (Sunnyvale): Sat Jun 29 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/916440918457">San Francisco, CA (Milpitas): Sat Jun 29 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/916431179327">San Francisco, CA (Pleasanton): Sun Jun 30 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/926312564817">San Francisco, CA (San Jose): Sun Jun 30 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/916326165227">San Francisco, CA (Fremont): Sun Jun 30 at 7:00pm</a></li>
          <li><a href="https://www.txst.edu/mathworks/camps/summer-math-camps-information/hsmc.html">San Marcos, TX (Mathworks Honors Summer Math Camp): Mon Jul 1</a></li>
          <li><a href="https://pgretreat.org/">Mt. Sterling, OH (PG Retreat): Tue Jul 2</a></li>
          <li><a href="https://rossprogram.org/">Columbus, OH (Ross Mathematics Program): Wed Jul 3</a></li>
          <li><a href="https://www.mathcamp.org/">Tacoma, WA (Canada/USA Mathcamp): Thu Jul 4</a></li>
          <li><a href="https://sumac.spcs.stanford.edu/">Stanford, CA (Stanford University Math Camp): Fri Jul 5</a></li>
          <li><a href="https://www.eventbrite.com/e/934699861447">Vancouver (University of British Columbia): Sat Jul 6 at 10:30am</a></li>
          <li><a href="https://www.eventbrite.com/e/885100107247">Vancouver (West Vancouver): Sat Jul 6 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/927519996277">Vancouver (South Surrey): Sun Jul 7 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/927524890917">Vancouver (South Surrey): Sun Jul 7 at 1:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/910832593807">Monmouth County, NJ: Tue Jul 9 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/910830186607">Basking Ridge, NJ: Wed Jul 10 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/910843105247">Bergen County, NJ: Thu Jul 11</a></li>
          <li><a href="https://www.eventbrite.com/e/910845933707">Suffolk County, NY: Fri Jul 12</a></li>
          <li><a href="https://www.imo2024.uk/">Bath, United Kingdom (International Mathematical Olympiad): Jul 14&ndash;21</a></li>
          <li><a href={tUrl("UK London")}>London: Wed Jul 17</a></li>
          <li><a href="https://imof.co/reunion/">United Kingdom (International Mathematical Olympiad Alumni Reunion, lead organizer): Jul 22&ndash;23</a></li>
          <li><a href="https://www.eventbrite.com/e/914719299047">Cleveland, OH: Thu Jul 25 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914726861667">Detroit, MI: Fri Jul 26 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/949088087017">Toronto: Sat Jul 27 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/914731736247">Toronto: Sat Jul 27 at 4:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914741565647">Buffalo, NY: Sun Jul 28 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914746069117">Rochester, NY: Sun Jul 28 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914747964787">Syracuse, NY: Mon Jul 29 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914748797277">Ottawa: Tue Jul 30 at 7:00pm</a></li>
          <li><a href="https://www.hertzfoundation.org/event/2024-summer-workshop/">Mont-Tremblant, Canada (Hertz Foundation Summer Workshop, co-organizer): Aug 1&ndash;4</a></li>
          <li><a href="https://www.eventbrite.com/e/965359214397">Montreal: Sun Aug 4 at 3:00pm (in Chinese)</a></li>
          <li><a href="https://www.eventbrite.com/e/914751074087">Montreal: Sun Aug 4 at 7:00pm (in English)</a></li>
          <li><a href="https://www.eventbrite.com/e/914752678887">Westchester, NY: Mon Aug 5 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914754253597">Philadelphia, PA: Tue Aug 6 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914755998817">Fairfax County, VA: Wed Aug 7 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914757272627">Durham, NC: Thu Aug 8 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914757924577">Charlotte, NC: Fri Aug 9 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914762879397">Atlanta, GA (Norcross): Sat Aug 10 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/914763431047">Atlanta, GA (Alpharetta): Sat Aug 10 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914764744977">Birmingham, AL: Sun Aug 11 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/914768807127">Huntsville, AL: Sun Aug 11 at 3:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914770151147">Nashville, TN (Murfreesboro): Mon Aug 12 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914780863187">Louisville, KY: Tue Aug 13 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914779920367">Indianapolis, IN: Wed Aug 14 at 7:00pm</a></li>
          <li><a href="https://summit.newturing.ai/">Ho Chi Minh City, Vietnam (New Turing Institute GenAI Summit): Aug 16&ndash;19</a></li>
          <li><a href="https://www.eventbrite.com/e/914781454957">Cincinnati, OH: Wed Aug 21 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914782437897">Columbus, OH: Thu Aug 22 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/909876504117">San Francisco, CA (Dublin): Fri Aug 23 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/999616107687">Sacramento, CA (Roseville): Sat Aug 24 at 1:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/991410995977">San Francisco, CA (Cupertino): Sat Aug 24 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/998777258667">San Francisco, CA (Milpitas): Sun Aug 25 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/998780989827">San Francisco, CA (Milpitas): Sun Aug 25 at 3:00pm</a></li>
          <li><a href={tUrl("CA Irvine")}>Irvine, CA: Mon Aug 26</a></li>
          <li>Syosset, NY (Syosset Central School District Superintendent's Conference Day): Tue Aug 27 at 9:00am</li>
          <li><a href="https://www.eventbrite.com/e/1003920913477">Oklahoma City, OK (Oklahoma School of Science and Math): Sat Sep 14 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/1002592911387">Oklahoma City, OK (Norman, OK): Sat Sep 14 at 7:00pm</a></li>
          <li><a href="https://www.cmu.edu/mscf">New York, NY (Carnegie Mellon University Master of Science in Computational Finance alumni event): Mon Sep 16</a></li>
          <li><a href="https://fpnetwork.org/event-5763945">Miami, FL (Florida Philanthropic Network Miami Math Meeting): Tue Sep 17</a></li>
          <li><a href="https://www.heidelberg-laureate-forum.org">Heidelberg, Germany (Heidelberg Laureate Forum): Tue Sep 24</a></li>
          <li><a href="https://summermathprograms.org/conference.html">Atlanta, GA (Summer Mathematics Programs Consortium Conference): Sat Sep 21</a></li>
          <li>Los Angeles, CA (Irvine): Sat Sep 28 at 10:00am in Chinese</li>
          <li><a href="https://www.eventbrite.com/e/1029021078747">Los Angeles, CA (Irvine): Sat Sep 28 at 3:00pm</a></li>
          <li><a href="https://www.nuevaschool.org/about/conferences-and-community-programs/math-circle">San Francisco, CA (Nueva Math Circle): Sat Oct 5</a></li>
          <li><a href="https://mathprize.atfoundation.org">Boston, MA (Math Prize for Girls): Sun Oct 6</a></li>
          <li>Los Angeles, CA (Watts, St. Lawrence of Brindisi School): Wed Oct 16</li>
          <li>Los Angeles, CA (Watts, Markham Middle School): Wed Oct 16</li>
          <li>Los Angeles, CA (Watts, Locke High School): Wed Oct 16</li>
          <li>Los Angeles, CA (South Central LA, Markham Middle School): Thu Oct 17</li>
          <li><a href="https://www.sbs.org">Long Island, NY (Stony Brook School): Mon Oct 21</a></li>
          <li><a href="https://cmimc.math.cmu.edu/cmwmc">Pittsburgh, PA (Carnegie Mellon Women's Mathematics Competition): Sat Oct 26 at 2:00pm</a></li>
          <li><a href="https://www.connectrwc.org">San Francisco, CA (Redwood City, Connect Community Charter School): Mon Oct 28</a></li>
          <li><a href="https://www.heliosschool.org">San Francisco, CA (Sunnyvale, Helios School): Mon Oct 28</a></li>
          <li><a href="https://berkeley.mt">San Francisco, CA (Berkeley Math Tournament): Sat Nov 2</a></li>
          <li><a href="https://www.eventbrite.com/e/1043235915707">Sacramento, CA: Sat Nov 2 at 3:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/1043242776227">Sacramento, CA: Sat Nov 2 at 6:30pm</a></li>
          <li><a href="https://massgifted.org/conference">Boston, MA (Harvard, Massachusetts Association for Gifted Education / Harvard Alumni for Education annual symposium on gifted education): Sun Nov 3 at 12:15pm</a></li>
          <li><a href="https://www.sjs.org">Houston, TX (St. John's School): Mon Nov 4</a></li>
          <li><a href="https://pma.caltech.edu/research-and-academics/mathematics/mathematics-seminars/charles-r-deprima-memorial-lecture-in-mathematics">Pasadena, CA (Caltech Charles DePrima Memorial Lecture in Mathematics): Tue Nov 5</a></li>
          <li><a href="https://www.math.ubc.ca/events/oct-8-2024-ai-here-how-can-we-help-all-humans-answer-math-twist">Vancouver (University of British Columbia): Sat Nov 9 at 11:30am</a></li>
          <li><a href="https://www.eventbrite.com/e/1075934973469">Vancouver: Sat Nov 9 at 3:30pm in Chinese</a></li>
          <li><a href="https://www.hmmt.org/">Boston, MA (Harvard&ndash;MIT Math Tournament student talk): Sun Nov 10 at 11:00am</a></li>
          <li><a href="https://www.ucfsd.org">Philadelphia, PA (West Chester, Unionville-Chadds Ford School District): Mon Nov 11</a></li>
          <li>Dallas, TX (Association of Chinese Professionals Annual MetroCon): Sat Nov 16</li>
          <li><a href="https://calendar.uab.edu/event/ai-is-here-how-can-we-help-all-the-humans">Birmingham, AL (University of Alabama Birmingham): Mon Nov 18 at 3:30pm</a></li>
          <li><a href="https://jason-shi-f9dm.squarespace.com">Princeton, NJ (Princeton University Math Competition): Sat Nov 23 at 10:00am</a></li>
          <li><a href="https://bit.ly/PoShenLohInUPD24">Manila, Philippines (University of Philippines Diliman): Mon Nov 25 at 10:00am</a></li>
          <li>Manila, Philippines (Quezon City Hall): Mon Nov 25 at 1:00pm</li>
          <li><a href="https://link.ateneomathsociety.org/PSLVisitsAteneo24">Manila, Philippines (Ateneo de Manila University): Mon Nov 25 at 5:00pm</a></li>
          <li>Shanghai (The Second Affiliated Middle School of East China Normal University Putuo Campus 华东师范大学第二附属中学普陀校区): Tue Nov 26 at 6:30pm</li>
          <li>Shanghai (Shanghai United International School Wanyuan Campus 上海市民办万源城协和双语学校): Wed Nov 27</li>
          <li>Shanghai (Shanghai United International School Jiaoke Campus 上海闵行区协和双语教科学校): Thu Nov 28</li>
          <li>Hong Kong (Pui Kiu College 香港培侨书院): Fri Nov 29 at 4:30pm</li>
          <li>Hong Kong (Pui Ching Middle School 香港培正中学): Sat Nov 30 at 9:30am</li>
          <li>Hong Kong (Han Academy 漢鼎書院): Sat Nov 30 at 3:00pm</li>
          <li>Changzhou, China (Changzhou Foreign Language School 常州外国语学校): Sun Dec 1 at 8:30am</li>

          <li>Shanghai (Shanghai American School): Mon Dec 2 at 1:30pm</li>
          <li><a href="https://sandiego.integirls.org">San Diego, CA (INTEGIRLS competition): Sun Dec 8</a></li>
          <li><a href="http://www.melbournvc.org">Royston, United Kingdom (Melbourn Village College): Tue Dec 10</a></li>
          <li><a href="https://arkvictoria.org">Birmingham, United Kingdom (Ark Victoria Academy): Wed Dec 11</a></li>
          <li><a href="https://arksoane.org">London, United Kingdom (Ark Soane Academy): Thu Dec 12</a></li>
          <li><a href="https://arkonline.org">London, United Kingdom (Ark): Thu Dec 12</a></li>
          <li><a href="https://www.lhsmath.org/LMT/Home">Boston, MA (Lexington Math Tournament): Sat Dec 14 at 9:15am</a></li>
          <li><a href="https://pryorwarriors.org">Pryor, MT (Crow Nation, Pryor School District): Mon Dec 16</a></li>
          <li><a href="https://lockwoodschool.org/">Lockwood, MT (Lockwood School District): Mon Dec 16</a></li>
          <li><a href="https://www.hardin.k12.mt.us/page/hardin-middle-school">Hardin, MT (Hardin Middle School): Tue Dec 17</a></li>
          <li><a href="https://www.custerschools.org">Custer, MT (Custer Public School District): Tue Dec 17</a></li>
          <li><a href="https://www.seedasdan.asia/en/ukmt-tmc">Shanghai (ASDAN China UKMT Team Maths Challenge): Sat Dec 21 and Sun Dec 22</a></li>
          <li>Shanghai, China: Sun Dec 22 at 3:00pm</li>
          <li>Ningbo, China (Concordia International School Ningbo 宁波协和学校): Mon Dec 23</li>
          <li>Shanghai, China (Qingpu World Foreign Language School 上海青浦世外学校): Tue Dec 24</li>
          <li>Shanghai, China (No. 2 High School of East China Normal University 华东师范大学第二附属中学): Tue Dec 24</li>
          <li>Shanghai, China (Lida Middle School 上海立达中学): Wed Dec 25</li>
          <li>Shanghai, China (Tianshan No. 1 Primary School 上海市长宁区天山第一小学): Wed Dec 25</li>
          <li>Beijing, China (Beijing International Bilingual School Haidian Academy 北京青苗国际学校海淀校区): Thu Dec 26</li>
          <li>Beijing, China (International School of Beijing 北京顺义国际学校): Fri Dec 27</li>
          <li>Tianjin, China (Yinghua Experimental School 天津英华实验学校): Sat Dec 28</li>
          <li>Beijing, China: Sun Dec 29 at 2:30pm</li>
          <li>Jinan, China (Jinan Foreign Language School 济南外国语): Mon Dec 30</li>
          <li>Jinan, China (Shandong Experimental School 山东省实验中学国际部): Mon Dec 30</li>
          <li>Qingdao, China (Hongwen School 宏文学校青岛校区): Tue Dec 31 at 9:00am</li>
          <li>Qingdao, China (Qingdao High School 青岛中学): Tue Dec 31 at 3:00pm</li>
          <li>Qingdao, China (Jinjialing School 青岛市崂山区金家岭学校): Tue Dec 31 at 7:00pm</li>
        </OLStyled>

        <h2>Stay Notified</h2>

        <PStyled>
          To stay notified about future tour stops, please subscribe to our <a
            href="https://eepurl.com/gExYzT">email newsletter</a> which sends
          timely information about math education and contest deadlines, every
          two weeks. If your language preference is Chinese, please follow the
          WeChat Public Account: 罗博深数学.
        </PStyled>

        <PStyled>
          To follow future online live streams, please subscribe to our <a
            href="https://www.youtube.com/channel/UCf78EJOm4wQ4xXwSS15PuxQ">Daily
            Challenge with Po-Shen Loh YouTube Channel</a>.
        </PStyled>

        <h2>Learn Math</h2>

        <PStyled>
          To learn math with Prof. Loh on a more regular basis, visit our <a href="https://live.poshenloh.com">classes</a>.
        </PStyled>

      </Container>
      <Footer />
    </Styles>
  );
}
